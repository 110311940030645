@import "src/styles/resolutions";

.customSignIn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  :global {
    .ant-spin-nested-loading > div > .ant-spin {
      max-height: 100%;
    }
  }

  .spinner {
    width: 100%;
    height: 100%;

    :global {
      .ant-spin-container,
      .ant-spin-blur {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  :global {
  }

  form {
    width: 100%;
    max-width: 600px;
    background-color: #fff;
    @media @mobile, @tablet {
      padding: 50px 75px 75px;
    }
    @media @desktop {
      padding: 50px 100px 100px;
    }
    border-radius: 10px;
    box-shadow: 0 1px 80px #03172b1c;
    margin: 0 auto;

    img {
      width: 100%;
      margin-bottom: 50px;
    }

    .password {
      label {
        font-size: 16px;
      }

      input {
        height: 50px;
        font-size: 20px;

        &::placeholder {
          font-size: 18px;
        }
      }
    }

    .submit {
      margin-top: 40px;
      margin-bottom: 0;

      button {
        width: 100%;
        height: 50px;
        border-radius: 36px;
        font-size: 16px;
        border: unset;
        color: #fff;
        background-color: #d972b7;

        > span {
          font-weight: 500;
          text-transform: uppercase;
        }
      }
    }
  }
}
