:global {
    .ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
        border-color: #fff;
    }
}

.mobileAnswers {
    width: 100%;

    :global {
        .ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
            border-color: unset !important;
        }

        .ant-slider-rail {
            top: 50%;
            transform: translateY(-50%);
            background: linear-gradient(135deg, rgba(236, 85, 141, 1) 0%, rgba(241, 208, 94, 1) 50%, rgba(28, 213, 159, 1) 100%);
            height: 6px;
            border-radius: 10px;
        }

        .ant-slider-dot {
            display: none;
        }

        .ant-slider-mark-text > div {
            margin-top: 14px;
            border-left: 1px solid var(--color);
            height: 10px;
        }

        .ant-slider-mark-text-active > div {
            height: 16px;
        }

        .ant-slider-handle {
            margin: 0;
            border: 10px solid #fff;
            box-shadow: 0 0 12px #03172B1C;
            background-color: var(--selectedColor);
            width: 36px;
            height: 36px;
            top: -100%;
        }
    }
}