.layout {
    height: 100%;

    :global {
        .ant-spin-nested-loading,
        .ant-spin-nested-loading > div > .ant-spin,
        .ant-spin-container, .ant-spin-container > div {
            max-height: none;
            height: 100%;
        }
    }

    .content {
        height: 100%;

        .carousel {
            height: 100%;

            .questions {
                height: 80%;
            }

            .navigation {
                background-color: #fff;
                height: 20%;
            }
        }
    }
}