@import "src/styles/resolutions";

@media @mobile, @tablet {

    .summary {

        background-color: #fff;
        height: 100%;

        .layout {
            display: flex;
            flex-direction: column;

            .header {
                padding: 0 5% 20px;
                margin: 0;

                h1 {
                    font-size: 36px;
                    font-weight: 600;
                    margin: 0;
                }

                .subheader {
                    font-size: 18px;
                    font-weight: normal;
                }

            }

            .module {
                font-size: 14px;
                font-weight: 500;
                padding: 0 5% 30px;

                .health {
                    color: #70CDE9;
                }

                .happiness {
                    color: #D972B7;
                }

                .success {
                    color: #FFB12F;
                }

                a {
                    font-size: 18px;
                    margin: 0 5px;
                    padding: 0;
                    font-weight: 600;
                }
            }

            .badge {
                width: 100vw;
                height: 100vh;
                background: #00000060;
                position: fixed;
                z-index: 100;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 10px;

                .container {

                    background: #fff;
                    padding: 10px 10px 40px;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;

                    > .controls {
                        display: flex;
                        align-items: center;
                        font-weight: 500;
                        font-size: 16px;
                        padding-bottom: 10px;
                        cursor: pointer;

                        > span {
                            > svg {
                                font-size: 20px;
                            }
                        }
                    }

                    > img {
                        object-fit: contain;
                        width: 100%;
                        height: 100%;
                        max-height: 80vh;
                    }

                }
            }

            .social {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                > .header {
                    font-size: 18px;
                    color: #7E8D9C;
                    font-weight: 500;
                }

                > .links {
                    display: grid;
                    grid-template-columns: max-content max-content max-content;
                    grid-column-gap: 10px;
                    margin-bottom: 20px;
                }
            }

            .actions {
                display: flex;
                flex-direction: column;
                padding: 0 5%;

                .progress {
                    margin-top: 10px;
                    border: 1px #D672A9 solid;
                    color: #D672A9;
                    height: 49px;
                    width: 100%;

                    > span {
                        font-size: 16px;
                        font-weight: 500;
                        padding: 0 10px;
                    }
                }

                .progress:hover {
                    opacity: 0.5;
                }

            }

            .cup {

                background-image: url("./assets/summary-mobile-background.svg");
                background-position: center bottom;
                background-size: cover;
                background-repeat: no-repeat;
                padding: 0 20px 50px;

                > div {

                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    .legend {
                        width: 100%;
                        font-size: 14px;
                        margin: 20px 0;
                        display: flex;
                        justify-content: space-around;

                        .health::before {
                            background-color: #70CDE9;
                        }

                        .happiness::before {
                            background-color: #D672A9;
                        }

                        .success::before {
                            background-color: #FFB12F;
                        }

                        > .health, .happiness, .success {
                            display: flex;
                            align-items: center;
                            font-weight: 500;
                        }

                        > .health::before, .happiness::before, .success::before {
                            display: inline-block;
                            content: '--';
                            color: transparent;
                            margin-right: 5px;
                            border-radius: 10px;
                            height: 10px !important;
                        }
                    }
                }
            }
        }
    }
}

@media @desktop {

    .summary {
        background-color: #fff;
        height: 100%;
        display: flex;

        .layout {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            .left {
                width: 50%;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                justify-content: center;

                .content {
                    max-width: 664px;
                    width: 100%;
                    padding: 50px 0 50px 50px;

                    .header {
                        height: 50%;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: flex-end;

                        h1 {
                            font-size: 50px;
                            line-height: 46px;
                            font-weight: 600;
                            margin: 10px 0;
                            white-space: nowrap;
                        }

                        .subheader {
                            font-size: 32px;
                            line-height: 70px;
                            font-weight: normal;
                        }

                    }

                    .actions {
                        display: flex;
                        padding: 20px 0;

                        .progress {
                            border: 1px #D672A9 solid;
                            color: #D672A9;
                            height: 49px;

                            > span {
                                font-size: 16px;
                                font-weight: 500;
                                padding: 0 10px;
                            }
                        }

                        .progress:hover {
                            opacity: 0.5;
                        }

                        > div:nth-of-type(1) {
                            margin-right: 20px;
                        }
                    }

                    .module {
                        font-size: 16px;
                        font-weight: 500;

                        .health {
                            color: #70CDE9;
                        }

                        .happiness {
                            color: #D972B7;
                        }

                        .success {
                            color: #FFB12F;
                        }

                        a {
                            font-size: 22px;
                            margin: 0 5px;
                            padding: 0;
                            font-weight: 600;
                        }
                    }

                    .social {
                        display: flex;
                        flex-direction: column;
                        margin-top: 20px;

                        > .header {
                            font-size: 18px;
                            color: #7E8D9C;
                            font-weight: 500;
                        }

                        > .links {
                            margin-top: 10px;
                            display: grid;
                            grid-template-columns: max-content max-content max-content;
                            grid-column-gap: 10px;
                        }
                    }
                }

            }

            .right {
                background-image: url('./assets/summary-background.svg');
                background-position: left;
                background-size: auto 1920px;
                width: 50%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;

                .cup > div {
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    .legend {
                        font-size: 16px;
                        margin: 50px 0;
                        width: 100%;
                        display: flex;
                        justify-content: space-around;

                        .health::before {
                            background-color: #70CDE9;
                        }

                        .happiness::before {
                            background-color: #D672A9;
                        }

                        .success::before {
                            background-color: #FFB12F;
                        }

                        > .health, .happiness, .success {
                            display: flex;
                            align-items: center;
                            font-weight: 500;
                        }

                        > .health::before, .happiness::before, .success::before {
                            display: inline-block;
                            content: '--';
                            color: transparent;
                            margin-right: 10px;
                            border-radius: 10px;
                            height: 10px !important;
                        }
                    }
                }
            }
        }

        .badge {
            width: 100vw;
            height: 100vh;
            background: #00000060;
            position: absolute;
            z-index: 100;
            display: flex;
            justify-content: center;
            align-items: center;

            .container {

                background: #fff;
                padding: 10px;
                display: flex;
                flex-direction: column;
                align-items: flex-end;

                > .controls {
                    display: flex;
                    align-items: center;
                    font-weight: 500;
                    font-size: 16px;
                    padding-bottom: 10px;
                    cursor: pointer;

                    > span {
                        > svg {
                            font-size: 20px;
                        }
                    }
                }

                > img {
                    width: auto;
                    height: 50vh;
                }

            }
        }
    }
}