@import "src/styles/resolutions";

.info {
  :global {
    .ant-modal-confirm-content {
      font-size: 18px;
    }
  }
}

@media @mobile, @tablet {
  .question {
    height: 100%;

    > div {
      height: 100%;

      .content {
        height: 100%;
        display: flex;
        flex-direction: column-reverse;

        > .left {
          height: 100%;
          background-color: #fff;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 0 20px;

          .layout {
            display: flex;
            flex-direction: column;
            justify-content: center;
            max-width: 500px;
            width: 100%;
            height: 95%;

            .header {
              display: flex;
              justify-content: space-between;
              align-items: center;

              > div {
                font-weight: 500;

                button {
                  padding: 0;

                  span {
                    font-weight: 500;
                  }
                }
              }
            }

            .category {
              font-size: 26px;
              font-weight: 400;
              margin: 20px 0;
              > span {
                font-weight: 600;
              }
            }

            .text {
              font-size: 2.3vh;
              font-weight: 400;
            }

            .answers {
              display: flex;
              justify-content: space-between;
              align-items: center;
              height: 100px;

              .roundButton {
                width: 16px;
                height: 16px;
                mask: url("./ellipse.svg") no-repeat center;
                -webkit-mask: url("./ellipse.svg") no-repeat center;
                mask-size: 16px;
                -webkit-mask-size: 16px;
                border: unset;
                cursor: pointer;
                transition: 200ms all;
              }

              .roundButton:hover {
                transform: scale(2);
              }

              :global {
                .active {
                  transform: scale(2);
                }
              }
            }

            .labels {
              display: flex;
              justify-content: space-between;

              > div {
                font-weight: 500;
              }
            }

            .navigation {
              display: flex;
              justify-content: space-between;

              .prev {
                padding: 0;
              }

              .next {
                span {
                  padding: 0 10px;
                }
              }

              button span {
                font-weight: 500;
              }
            }
          }
        }

        > .right {
          background-color: #f5f8fb;
          width: 100%;
          height: 100%;
          position: relative;

          > .image,
          .currentImage {
            position: absolute;
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            -webkit-transition: all 0.25s ease-in-out;
            transition: all 0.25s ease-in-out;

            &.visible {
              opacity: 1;
            }

            &.hidden {
              opacity: 0;
            }
          }
        }
      }
    }
  }
}

@media @desktop {
  .question {
    height: 100%;

    :global {
      .ant-spin-nested-loading > div > .ant-spin,
      .ant-spin-container,
      .ant-spin-container > div {
        max-height: none;
        height: 100%;
      }
    }

    > div {
      height: 100%;

      .content {
        height: 100%;
        display: flex;

        > .left {
          padding: 20px;
          height: 100%;
          width: 50%;
          background-color: #fff;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .layout {
            display: flex;
            flex-direction: column;
            justify-content: center;
            max-width: 500px;
            width: 100%;
            height: 100%;
            margin: 30px 0;

            .header {
              display: flex;
              justify-content: space-between;
              align-items: center;

              > div {
                font-weight: 500;

                button {
                  padding: 0;

                  span {
                    font-weight: 500;
                  }
                }
              }
            }

            .category {
              font-size: 30px;
              font-weight: 400;
              margin: 20px 0;
              > span {
                font-weight: 600;
              }
            }

            .text {
              font-size: 20px;
              font-weight: 400;
              min-height: 200px;
            }

            .answers {
              display: flex;
              justify-content: space-between;
              align-items: center;
              height: 100px;

              .roundButton {
                width: 30px;
                height: 30px;
                border: 3px solid #fff;
                border-radius: 100%;
                -moz-border-radius: 100%;
                -webkit-border-radius: 100%;
                background: #000;
                cursor: pointer;
                transition: 200ms all;
              }

              .roundButton:hover {
                transform: scale(2);
              }

              :global {
                .active {
                  transform: scale(2);
                }
              }
            }

            .labels {
              display: flex;
              justify-content: space-between;

              > div {
                font-weight: 500;
              }
            }

            .navigation {
              display: flex;
              justify-content: space-between;

              .prev {
                padding: 0;
              }

              .next {
                span {
                  padding: 0 10px;
                }
              }

              button span {
                font-weight: 500;
              }
            }
          }
        }

        > .right {
          background-color: #f5f8fb;
          width: 50%;
          height: 100%;
          position: relative;

          > .image,
          .currentImage {
            position: absolute;
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            -webkit-transition: all 0.5s ease-in-out;
            transition: all 0.5s ease-in-out;

            &.visible {
              opacity: 1;
            }

            &.hidden {
              opacity: 0;
            }
          }
        }
      }
    }
  }
}
