html {
    min-width: 320px;
    scroll-behavior: smooth;
}

html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    min-height: 100%;
}

body * {
    font-family: 'Poppins', sans-serif !important;
    font-weight: 300;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#root {
    min-height: 100%;
    display: flex;
    flex: 1 1 100%;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    #root {
        height: 100%;
    }
}

.app_3g35f {
  display: flex;
  display: -webkit-flexbox;
  flex-direction: column;
  flex-grow: 1;
  background-color: #F6F8FB;
  width: 100%;
}
.app_3g35f > div {
  display: flex;
  display: -webkit-flexbox;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.wrapper_26TVO {
  flex: 1 1 100%;
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 719px), only screen and (min-width: 720px) and (max-width: 959px) {
  .container_1-dql {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-height: 400px;
  }
}
@media only screen and (min-width: 960px) {
  .container_1-dql {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100%;
  }
}
@media only screen and (min-width: 960px) and screen and (-ms-high-contrast: active), only screen and (min-width: 960px) and (-ms-high-contrast: none) {
  .container_1-dql {
    height: 100%;
  }
}
.ant-spin-dot_2aw5Z {
  width: 40px;
  height: 40px;
}
.ant-spin-dot-item_2X80K {
  width: 20px;
  height: 20px;
}
.ant-spin-nested-loading_2ro-b > div > .ant-spin_3FDjC {
  max-height: none;
  min-height: 100%;
}

.customSignIn_18kPV {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.customSignIn_18kPV .ant-spin-nested-loading > div > .ant-spin {
  max-height: 100%;
}
.customSignIn_18kPV .spinner_21hdh {
  width: 100%;
  height: 100%;
}
.customSignIn_18kPV .spinner_21hdh .ant-spin-container,
.customSignIn_18kPV .spinner_21hdh .ant-spin-blur {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.customSignIn_18kPV form {
  width: 100%;
  max-width: 600px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 1px 80px #03172b1c;
  margin: 0 auto;
}
@media only screen and (max-width: 719px), only screen and (min-width: 720px) and (max-width: 959px) {
  .customSignIn_18kPV form {
    padding: 50px 75px 75px;
  }
}
@media only screen and (min-width: 960px) {
  .customSignIn_18kPV form {
    padding: 50px 100px 100px;
  }
}
.customSignIn_18kPV form img {
  width: 100%;
  margin-bottom: 50px;
}
.customSignIn_18kPV form .password_2Hk8d label {
  font-size: 16px;
}
.customSignIn_18kPV form .password_2Hk8d input {
  height: 50px;
  font-size: 20px;
}
.customSignIn_18kPV form .password_2Hk8d input::-webkit-input-placeholder {
  font-size: 18px;
}
.customSignIn_18kPV form .password_2Hk8d input:-ms-input-placeholder {
  font-size: 18px;
}
.customSignIn_18kPV form .password_2Hk8d input::-ms-input-placeholder {
  font-size: 18px;
}
.customSignIn_18kPV form .password_2Hk8d input::placeholder {
  font-size: 18px;
}
.customSignIn_18kPV form .submit_Nhhkb {
  margin-top: 40px;
  margin-bottom: 0;
}
.customSignIn_18kPV form .submit_Nhhkb button {
  width: 100%;
  height: 50px;
  border-radius: 36px;
  font-size: 16px;
  border: unset;
  color: #fff;
  background-color: #d972b7;
}
.customSignIn_18kPV form .submit_Nhhkb button > span {
  font-weight: 500;
  text-transform: uppercase;
}

.ant-notification {
  width: auto;
  max-width: 600px;
  overflow-wrap: break-word;
}
.notification_1Dqrk .ant-notification-notice-message {
  font-weight: 500;
  font-size: 16px;
}
.notification_1Dqrk .ant-notification-notice-description {
  font-size: 16px;
  margin: 0 50px 0 60px;
}
.notification_1Dqrk .ant-notification-notice-message {
  margin: 0 50px 0 60px;
}
.notification_1Dqrk .ant-notification-notice-with-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.notification_1Dqrk .ant-notification-notice-with-icon > i {
  font-size: 32px;
}

.container_-uFuR {
  background-color: #ECEFF4;
  height: 100%;
  min-height: 100%;
  padding: 50px 20px;
}
.container_-uFuR .ant-spin-nested-loading,
.container_-uFuR .ant-spin,
.container_-uFuR .ant-spin-blur {
  min-height: 100%;
  height: 100%;
}
.container_-uFuR .ant-spin-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.container_-uFuR .title_2AEUX {
  font-weight: bold;
  font-size: calc(20px + 2vw);
  text-align: center;
}
.container_-uFuR .subtitle_32M-x {
  font-size: calc(8px + 1.3vw);
  text-align: center;
  padding: 15px 0 50px;
}
.container_-uFuR form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1080px;
  width: 100%;
}
.container_-uFuR form label {
  font-size: 14px;
  font-weight: 500;
}
.container_-uFuR form .ant-input {
  font-size: 16px;
  height: 45px;
}
.container_-uFuR form .yearPicker_mpLFe .ant-select-selection--single,
.container_-uFuR form .yearPicker_mpLFe .ant-select-selection__rendered {
  font-size: 16px;
  height: 45px;
  line-height: 44px;
}
.container_-uFuR form .infoButtonMixin_3raYi {
  padding: 6px 15px 6px 0px;
  margin-top: 5px;
  margin-bottom: 20px;
  cursor: pointer;
}
.container_-uFuR form .chevron_1e_Lv {
  margin-left: 3px;
}
.container_-uFuR form .infoContainer_2pOvH {
  height: 57px;
  margin-top: 5px;
}
.container_-uFuR form .row_2NesV {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media only screen and (max-width: 719px) {
  .container_-uFuR form .row_2NesV {
    flex-direction: column;
  }
}
.container_-uFuR form .row_2NesV .col_3GhVy .moreInfoButton_7Kp74 {
  padding: 6px 15px 6px 0px;
  margin-top: 5px;
  margin-bottom: 20px;
  cursor: pointer;
}
@media only screen and (max-width: 719px) {
  .container_-uFuR form .row_2NesV .col_3GhVy {
    width: 100%;
  }
}
@media only screen and (min-width: 720px) and (max-width: 959px), only screen and (min-width: 960px) {
  .container_-uFuR form .row_2NesV .col_3GhVy {
    width: calc(50% - 10px);
  }
}
.container_-uFuR form .center_eEZEj {
  width: 100%;
}
.container_-uFuR form .center_eEZEj .ant-form-item-control {
  display: flex;
  justify-content: center;
}
.container_-uFuR form .center_eEZEj .moreInfoButton_7Kp74 {
  padding: 6px 15px 6px 0px;
  margin-top: 5px;
  margin-bottom: 20px;
  cursor: pointer;
}
.container_-uFuR form .submit_25IbT {
  text-align: center;
}
.container_-uFuR form .submit_25IbT button {
  margin: 50px 0 0;
  width: 250px;
  height: 50px;
  font-size: 20px;
  border: unset;
  text-transform: uppercase;
}
.container_-uFuR form .submit_25IbT button > span {
  font-weight: 500;
}
.container_-uFuR .treeStyle_Ps18O {
  color: #0C3D57;
  font-size: 1.2em;
  background-color: #ECEFF4;
}
.container_-uFuR .treeStyle_Ps18O .treeNode_wMkd8 .ant-tree-node-content-wrapper:hover {
  background-color: rgb(216 113 9.15%) !important;
}
.container_-uFuR .treeStyle_Ps18O .treeNode_wMkd8 .ant-tree-node-selected {
  background-color: rgb(216 113 9.15%) !important;
}
.container_-uFuR .treeStyle_Ps18O .treeNode_wMkd8 .ant-tree-node-selected .ant-tree-title {
  font-weight: 400;
}
.container_-uFuR .treeStyle_Ps18O .treeNode_wMkd8 .ant-tree-title {
  color: #0C3D57;
}

.select_ACA1b {
  background-color: #F5F8FB;
}
.select_ACA1b .ant-select-selection--single,
.select_ACA1b .ant-select-selection__rendered {
  font-size: 16px;
  height: 45px;
  line-height: 44px;
}

.layout_1cdu3 {
  height: 100%;
}
.layout_1cdu3 .ant-spin-nested-loading,
.layout_1cdu3 .ant-spin-nested-loading > div > .ant-spin,
.layout_1cdu3 .ant-spin-container,
.layout_1cdu3 .ant-spin-container > div {
  max-height: none;
  height: 100%;
}
.layout_1cdu3 .content_n3yl7 {
  height: 100%;
}
.layout_1cdu3 .content_n3yl7 .carousel_4h8JB {
  height: 100%;
}
.layout_1cdu3 .content_n3yl7 .carousel_4h8JB .questions_1XLZ6 {
  height: 80%;
}
.layout_1cdu3 .content_n3yl7 .carousel_4h8JB .navigation_3eOFn {
  background-color: #fff;
  height: 20%;
}

.info_1DYEP .ant-modal-confirm-content {
  font-size: 18px;
}
@media only screen and (max-width: 719px), only screen and (min-width: 720px) and (max-width: 959px) {
  .question_2pyyH {
    height: 100%;
  }
  .question_2pyyH > div {
    height: 100%;
  }
  .question_2pyyH > div .content_2qcMh {
    height: 100%;
    display: flex;
    flex-direction: column-reverse;
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U {
    height: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U .layout_2-e7Z {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 500px;
    width: 100%;
    height: 95%;
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U .layout_2-e7Z .header_2X-g- {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U .layout_2-e7Z .header_2X-g- > div {
    font-weight: 500;
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U .layout_2-e7Z .header_2X-g- > div button {
    padding: 0;
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U .layout_2-e7Z .header_2X-g- > div button span {
    font-weight: 500;
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U .layout_2-e7Z .category_1md9v {
    font-size: 26px;
    font-weight: 400;
    margin: 20px 0;
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U .layout_2-e7Z .category_1md9v > span {
    font-weight: 600;
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U .layout_2-e7Z .text_2TQvq {
    font-size: 2.3vh;
    font-weight: 400;
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U .layout_2-e7Z .answers_3Dycq {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U .layout_2-e7Z .answers_3Dycq .roundButton_1Ubo5 {
    width: 16px;
    height: 16px;
    mask: url(/static/media/ellipse.ef118565.svg) no-repeat center;
    -webkit-mask: url(/static/media/ellipse.ef118565.svg) no-repeat center;
    mask-size: 16px;
    -webkit-mask-size: 16px;
    border: unset;
    cursor: pointer;
    transition: 200ms all;
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U .layout_2-e7Z .answers_3Dycq .roundButton_1Ubo5:hover {
    transform: scale(2);
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U .layout_2-e7Z .answers_3Dycq .active {
    transform: scale(2);
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U .layout_2-e7Z .labels_3vH-P {
    display: flex;
    justify-content: space-between;
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U .layout_2-e7Z .labels_3vH-P > div {
    font-weight: 500;
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U .layout_2-e7Z .navigation_iDnPu {
    display: flex;
    justify-content: space-between;
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U .layout_2-e7Z .navigation_iDnPu .prev_lgmWB {
    padding: 0;
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U .layout_2-e7Z .navigation_iDnPu .next_2GQc5 span {
    padding: 0 10px;
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U .layout_2-e7Z .navigation_iDnPu button span {
    font-weight: 500;
  }
  .question_2pyyH > div .content_2qcMh > .right_Zf9Ee {
    background-color: #f5f8fb;
    width: 100%;
    height: 100%;
    position: relative;
  }
  .question_2pyyH > div .content_2qcMh > .right_Zf9Ee > .image_uHzzn,
  .question_2pyyH > div .content_2qcMh > .right_Zf9Ee .currentImage_FpxrT {
    position: absolute;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transition: all 0.25s ease-in-out;
  }
  .question_2pyyH > div .content_2qcMh > .right_Zf9Ee > .image_uHzzn.visible_1ccyD,
  .question_2pyyH > div .content_2qcMh > .right_Zf9Ee .currentImage_FpxrT.visible_1ccyD {
    opacity: 1;
  }
  .question_2pyyH > div .content_2qcMh > .right_Zf9Ee > .image_uHzzn.hidden_3vly0,
  .question_2pyyH > div .content_2qcMh > .right_Zf9Ee .currentImage_FpxrT.hidden_3vly0 {
    opacity: 0;
  }
}
@media only screen and (min-width: 960px) {
  .question_2pyyH {
    height: 100%;
  }
  .question_2pyyH .ant-spin-nested-loading > div > .ant-spin,
  .question_2pyyH .ant-spin-container,
  .question_2pyyH .ant-spin-container > div {
    max-height: none;
    height: 100%;
  }
  .question_2pyyH > div {
    height: 100%;
  }
  .question_2pyyH > div .content_2qcMh {
    height: 100%;
    display: flex;
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U {
    padding: 20px;
    height: 100%;
    width: 50%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U .layout_2-e7Z {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 500px;
    width: 100%;
    height: 100%;
    margin: 30px 0;
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U .layout_2-e7Z .header_2X-g- {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U .layout_2-e7Z .header_2X-g- > div {
    font-weight: 500;
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U .layout_2-e7Z .header_2X-g- > div button {
    padding: 0;
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U .layout_2-e7Z .header_2X-g- > div button span {
    font-weight: 500;
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U .layout_2-e7Z .category_1md9v {
    font-size: 30px;
    font-weight: 400;
    margin: 20px 0;
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U .layout_2-e7Z .category_1md9v > span {
    font-weight: 600;
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U .layout_2-e7Z .text_2TQvq {
    font-size: 20px;
    font-weight: 400;
    min-height: 200px;
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U .layout_2-e7Z .answers_3Dycq {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U .layout_2-e7Z .answers_3Dycq .roundButton_1Ubo5 {
    width: 30px;
    height: 30px;
    border: 3px solid #fff;
    border-radius: 100%;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    background: #000;
    cursor: pointer;
    transition: 200ms all;
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U .layout_2-e7Z .answers_3Dycq .roundButton_1Ubo5:hover {
    transform: scale(2);
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U .layout_2-e7Z .answers_3Dycq .active {
    transform: scale(2);
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U .layout_2-e7Z .labels_3vH-P {
    display: flex;
    justify-content: space-between;
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U .layout_2-e7Z .labels_3vH-P > div {
    font-weight: 500;
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U .layout_2-e7Z .navigation_iDnPu {
    display: flex;
    justify-content: space-between;
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U .layout_2-e7Z .navigation_iDnPu .prev_lgmWB {
    padding: 0;
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U .layout_2-e7Z .navigation_iDnPu .next_2GQc5 span {
    padding: 0 10px;
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U .layout_2-e7Z .navigation_iDnPu button span {
    font-weight: 500;
  }
  .question_2pyyH > div .content_2qcMh > .right_Zf9Ee {
    background-color: #f5f8fb;
    width: 50%;
    height: 100%;
    position: relative;
  }
  .question_2pyyH > div .content_2qcMh > .right_Zf9Ee > .image_uHzzn,
  .question_2pyyH > div .content_2qcMh > .right_Zf9Ee .currentImage_FpxrT {
    position: absolute;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transition: all 0.5s ease-in-out;
  }
  .question_2pyyH > div .content_2qcMh > .right_Zf9Ee > .image_uHzzn.visible_1ccyD,
  .question_2pyyH > div .content_2qcMh > .right_Zf9Ee .currentImage_FpxrT.visible_1ccyD {
    opacity: 1;
  }
  .question_2pyyH > div .content_2qcMh > .right_Zf9Ee > .image_uHzzn.hidden_3vly0,
  .question_2pyyH > div .content_2qcMh > .right_Zf9Ee .currentImage_FpxrT.hidden_3vly0 {
    opacity: 0;
  }
}

.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  border-color: #fff;
}
.mobileAnswers_2LHUQ {
  width: 100%;
}
.mobileAnswers_2LHUQ .ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  border-color: unset !important;
}
.mobileAnswers_2LHUQ .ant-slider-rail {
  top: 50%;
  transform: translateY(-50%);
  background: linear-gradient(135deg, #ec558d 0%, #f1d05e 50%, #1cd59f 100%);
  height: 6px;
  border-radius: 10px;
}
.mobileAnswers_2LHUQ .ant-slider-dot {
  display: none;
}
.mobileAnswers_2LHUQ .ant-slider-mark-text > div {
  margin-top: 14px;
  border-left: 1px solid var(--color);
  height: 10px;
}
.mobileAnswers_2LHUQ .ant-slider-mark-text-active > div {
  height: 16px;
}
.mobileAnswers_2LHUQ .ant-slider-handle {
  margin: 0;
  border: 10px solid #fff;
  box-shadow: 0 0 12px #03172B1C;
  background-color: var(--selectedColor);
  width: 36px;
  height: 36px;
  top: -100%;
}

@media only screen and (max-width: 719px), only screen and (min-width: 720px) and (max-width: 959px) {
  .summary_1SwA0 {
    background-color: #fff;
    height: 100%;
  }
  .summary_1SwA0 .layout_3NSaK {
    display: flex;
    flex-direction: column;
  }
  .summary_1SwA0 .layout_3NSaK .header_3RW7a {
    padding: 0 5% 20px;
    margin: 0;
  }
  .summary_1SwA0 .layout_3NSaK .header_3RW7a h1 {
    font-size: 36px;
    font-weight: 600;
    margin: 0;
  }
  .summary_1SwA0 .layout_3NSaK .header_3RW7a .subheader_2BKPH {
    font-size: 18px;
    font-weight: normal;
  }
  .summary_1SwA0 .layout_3NSaK .module_1rU5M {
    font-size: 14px;
    font-weight: 500;
    padding: 0 5% 30px;
  }
  .summary_1SwA0 .layout_3NSaK .module_1rU5M .health_SUpiu {
    color: #70CDE9;
  }
  .summary_1SwA0 .layout_3NSaK .module_1rU5M .happiness_GLCVW {
    color: #D972B7;
  }
  .summary_1SwA0 .layout_3NSaK .module_1rU5M .success_2t-Rb {
    color: #FFB12F;
  }
  .summary_1SwA0 .layout_3NSaK .module_1rU5M a {
    font-size: 18px;
    margin: 0 5px;
    padding: 0;
    font-weight: 600;
  }
  .summary_1SwA0 .layout_3NSaK .badge_1iQyp {
    width: 100vw;
    height: 100vh;
    background: #00000060;
    position: fixed;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }
  .summary_1SwA0 .layout_3NSaK .badge_1iQyp .container_3xIvN {
    background: #fff;
    padding: 10px 10px 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .summary_1SwA0 .layout_3NSaK .badge_1iQyp .container_3xIvN > .controls_kvKoX {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    padding-bottom: 10px;
    cursor: pointer;
  }
  .summary_1SwA0 .layout_3NSaK .badge_1iQyp .container_3xIvN > .controls_kvKoX > span > svg {
    font-size: 20px;
  }
  .summary_1SwA0 .layout_3NSaK .badge_1iQyp .container_3xIvN > img {
    object-fit: contain;
    width: 100%;
    height: 100%;
    max-height: 80vh;
  }
  .summary_1SwA0 .layout_3NSaK .social_2HXQr {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .summary_1SwA0 .layout_3NSaK .social_2HXQr > .header_3RW7a {
    font-size: 18px;
    color: #7E8D9C;
    font-weight: 500;
  }
  .summary_1SwA0 .layout_3NSaK .social_2HXQr > .links_b3GRU {
    display: grid;
    grid-template-columns: -webkit-max-content -webkit-max-content -webkit-max-content;
    grid-template-columns: max-content max-content max-content;
    grid-column-gap: 10px;
    margin-bottom: 20px;
  }
  .summary_1SwA0 .layout_3NSaK .actions_N4ugu {
    display: flex;
    flex-direction: column;
    padding: 0 5%;
  }
  .summary_1SwA0 .layout_3NSaK .actions_N4ugu .progress_1p6-f {
    margin-top: 10px;
    border: 1px #D672A9 solid;
    color: #D672A9;
    height: 49px;
    width: 100%;
  }
  .summary_1SwA0 .layout_3NSaK .actions_N4ugu .progress_1p6-f > span {
    font-size: 16px;
    font-weight: 500;
    padding: 0 10px;
  }
  .summary_1SwA0 .layout_3NSaK .actions_N4ugu .progress_1p6-f:hover {
    opacity: 0.5;
  }
  .summary_1SwA0 .layout_3NSaK .cup_1g0yj {
    background-image: url(/static/media/summary-mobile-background.63a424f2.svg);
    background-position: center bottom;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 0 20px 50px;
  }
  .summary_1SwA0 .layout_3NSaK .cup_1g0yj > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .summary_1SwA0 .layout_3NSaK .cup_1g0yj > div .legend_2VP-d {
    width: 100%;
    font-size: 14px;
    margin: 20px 0;
    display: flex;
    justify-content: space-around;
  }
  .summary_1SwA0 .layout_3NSaK .cup_1g0yj > div .legend_2VP-d .health_SUpiu::before {
    background-color: #70CDE9;
  }
  .summary_1SwA0 .layout_3NSaK .cup_1g0yj > div .legend_2VP-d .happiness_GLCVW::before {
    background-color: #D672A9;
  }
  .summary_1SwA0 .layout_3NSaK .cup_1g0yj > div .legend_2VP-d .success_2t-Rb::before {
    background-color: #FFB12F;
  }
  .summary_1SwA0 .layout_3NSaK .cup_1g0yj > div .legend_2VP-d > .health_SUpiu,
  .summary_1SwA0 .layout_3NSaK .cup_1g0yj > div .legend_2VP-d .happiness_GLCVW,
  .summary_1SwA0 .layout_3NSaK .cup_1g0yj > div .legend_2VP-d .success_2t-Rb {
    display: flex;
    align-items: center;
    font-weight: 500;
  }
  .summary_1SwA0 .layout_3NSaK .cup_1g0yj > div .legend_2VP-d > .health_SUpiu::before,
  .summary_1SwA0 .layout_3NSaK .cup_1g0yj > div .legend_2VP-d .happiness_GLCVW::before,
  .summary_1SwA0 .layout_3NSaK .cup_1g0yj > div .legend_2VP-d .success_2t-Rb::before {
    display: inline-block;
    content: '--';
    color: transparent;
    margin-right: 5px;
    border-radius: 10px;
    height: 10px !important;
  }
}
@media only screen and (min-width: 960px) {
  .summary_1SwA0 {
    background-color: #fff;
    height: 100%;
    display: flex;
  }
  .summary_1SwA0 .layout_3NSaK {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .summary_1SwA0 .layout_3NSaK .left_9HLJw {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
  }
  .summary_1SwA0 .layout_3NSaK .left_9HLJw .content_1ehQk {
    max-width: 664px;
    width: 100%;
    padding: 50px 0 50px 50px;
  }
  .summary_1SwA0 .layout_3NSaK .left_9HLJw .content_1ehQk .header_3RW7a {
    height: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
  }
  .summary_1SwA0 .layout_3NSaK .left_9HLJw .content_1ehQk .header_3RW7a h1 {
    font-size: 50px;
    line-height: 46px;
    font-weight: 600;
    margin: 10px 0;
    white-space: nowrap;
  }
  .summary_1SwA0 .layout_3NSaK .left_9HLJw .content_1ehQk .header_3RW7a .subheader_2BKPH {
    font-size: 32px;
    line-height: 70px;
    font-weight: normal;
  }
  .summary_1SwA0 .layout_3NSaK .left_9HLJw .content_1ehQk .actions_N4ugu {
    display: flex;
    padding: 20px 0;
  }
  .summary_1SwA0 .layout_3NSaK .left_9HLJw .content_1ehQk .actions_N4ugu .progress_1p6-f {
    border: 1px #D672A9 solid;
    color: #D672A9;
    height: 49px;
  }
  .summary_1SwA0 .layout_3NSaK .left_9HLJw .content_1ehQk .actions_N4ugu .progress_1p6-f > span {
    font-size: 16px;
    font-weight: 500;
    padding: 0 10px;
  }
  .summary_1SwA0 .layout_3NSaK .left_9HLJw .content_1ehQk .actions_N4ugu .progress_1p6-f:hover {
    opacity: 0.5;
  }
  .summary_1SwA0 .layout_3NSaK .left_9HLJw .content_1ehQk .actions_N4ugu > div:nth-of-type(1) {
    margin-right: 20px;
  }
  .summary_1SwA0 .layout_3NSaK .left_9HLJw .content_1ehQk .module_1rU5M {
    font-size: 16px;
    font-weight: 500;
  }
  .summary_1SwA0 .layout_3NSaK .left_9HLJw .content_1ehQk .module_1rU5M .health_SUpiu {
    color: #70CDE9;
  }
  .summary_1SwA0 .layout_3NSaK .left_9HLJw .content_1ehQk .module_1rU5M .happiness_GLCVW {
    color: #D972B7;
  }
  .summary_1SwA0 .layout_3NSaK .left_9HLJw .content_1ehQk .module_1rU5M .success_2t-Rb {
    color: #FFB12F;
  }
  .summary_1SwA0 .layout_3NSaK .left_9HLJw .content_1ehQk .module_1rU5M a {
    font-size: 22px;
    margin: 0 5px;
    padding: 0;
    font-weight: 600;
  }
  .summary_1SwA0 .layout_3NSaK .left_9HLJw .content_1ehQk .social_2HXQr {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }
  .summary_1SwA0 .layout_3NSaK .left_9HLJw .content_1ehQk .social_2HXQr > .header_3RW7a {
    font-size: 18px;
    color: #7E8D9C;
    font-weight: 500;
  }
  .summary_1SwA0 .layout_3NSaK .left_9HLJw .content_1ehQk .social_2HXQr > .links_b3GRU {
    margin-top: 10px;
    display: grid;
    grid-template-columns: -webkit-max-content -webkit-max-content -webkit-max-content;
    grid-template-columns: max-content max-content max-content;
    grid-column-gap: 10px;
  }
  .summary_1SwA0 .layout_3NSaK .right_1y3rq {
    background-image: url(/static/media/summary-background.8a090630.svg);
    background-position: left;
    background-size: auto 1920px;
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  .summary_1SwA0 .layout_3NSaK .right_1y3rq .cup_1g0yj > div {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .summary_1SwA0 .layout_3NSaK .right_1y3rq .cup_1g0yj > div .legend_2VP-d {
    font-size: 16px;
    margin: 50px 0;
    width: 100%;
    display: flex;
    justify-content: space-around;
  }
  .summary_1SwA0 .layout_3NSaK .right_1y3rq .cup_1g0yj > div .legend_2VP-d .health_SUpiu::before {
    background-color: #70CDE9;
  }
  .summary_1SwA0 .layout_3NSaK .right_1y3rq .cup_1g0yj > div .legend_2VP-d .happiness_GLCVW::before {
    background-color: #D672A9;
  }
  .summary_1SwA0 .layout_3NSaK .right_1y3rq .cup_1g0yj > div .legend_2VP-d .success_2t-Rb::before {
    background-color: #FFB12F;
  }
  .summary_1SwA0 .layout_3NSaK .right_1y3rq .cup_1g0yj > div .legend_2VP-d > .health_SUpiu,
  .summary_1SwA0 .layout_3NSaK .right_1y3rq .cup_1g0yj > div .legend_2VP-d .happiness_GLCVW,
  .summary_1SwA0 .layout_3NSaK .right_1y3rq .cup_1g0yj > div .legend_2VP-d .success_2t-Rb {
    display: flex;
    align-items: center;
    font-weight: 500;
  }
  .summary_1SwA0 .layout_3NSaK .right_1y3rq .cup_1g0yj > div .legend_2VP-d > .health_SUpiu::before,
  .summary_1SwA0 .layout_3NSaK .right_1y3rq .cup_1g0yj > div .legend_2VP-d .happiness_GLCVW::before,
  .summary_1SwA0 .layout_3NSaK .right_1y3rq .cup_1g0yj > div .legend_2VP-d .success_2t-Rb::before {
    display: inline-block;
    content: '--';
    color: transparent;
    margin-right: 10px;
    border-radius: 10px;
    height: 10px !important;
  }
  .summary_1SwA0 .badge_1iQyp {
    width: 100vw;
    height: 100vh;
    background: #00000060;
    position: absolute;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .summary_1SwA0 .badge_1iQyp .container_3xIvN {
    background: #fff;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .summary_1SwA0 .badge_1iQyp .container_3xIvN > .controls_kvKoX {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    padding-bottom: 10px;
    cursor: pointer;
  }
  .summary_1SwA0 .badge_1iQyp .container_3xIvN > .controls_kvKoX > span > svg {
    font-size: 20px;
  }
  .summary_1SwA0 .badge_1iQyp .container_3xIvN > img {
    width: auto;
    height: 50vh;
  }
}

.tcup_1dnxf {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 50px;
}
.tcup_1dnxf .content_2h9_S {
  position: relative;
  max-width: 100%;
  max-height: 100%;
}
.tcup_1dnxf .content_2h9_S .svgTCup_3hDJB {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.tcup_1dnxf .content_2h9_S .svgTCup_3hDJB rect {
  max-height: 0;
}
.tcup_1dnxf .content_2h9_S .svgTCup_3hDJB .empty_3Pt7c {
  fill: #fff;
}
.tcup_1dnxf .content_2h9_S .shadow_2vWb6 {
  position: absolute;
  left: -5%;
  z-index: 0;
  width: 110%;
}
@keyframes fill-tcup_cKqAr {
  from {
    y: 100%;
    height: 0;
  }
  to {
    height: 100%;
  }
}
.tcup_1dnxf .content_2h9_S .animated_29rAt {
  position: absolute;
  animation-name: fill-tcup_cKqAr;
  animation-iteration-count: 1;
  animation-duration: 1s;
  transform-origin: bottom;
}
.tcup_1dnxf .animation_3i7me {
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: 0;
  transform: translate(50%, 35%);
  margin: auto;
}

