@import "src/styles/resolutions";

.container {

    background-color: #ECEFF4;
    height: 100%;
    min-height: 100%;
    padding: 50px 20px;

    :global {
        .ant-spin-nested-loading, .ant-spin, .ant-spin-blur {
            min-height: 100%;
            height: 100%;
        }

        .ant-spin-container {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }

    .title {
        font-weight: bold;
        font-size: calc(20px + 2vw);
        text-align: center;
    }

    .subtitle {
        font-size: calc(8px + 1.3vw);
        text-align: center;
        padding: 15px 0 50px;
    }

    form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 1080px;
        width: 100%;

        label {
            font-size: 14px;
            font-weight: 500;
        }

        :global {
            .ant-input {
                font-size: 16px;
                height: 45px;
            }
        }

        .yearPicker {

            :global {
                .ant-select-selection--single, .ant-select-selection__rendered {
                    font-size: 16px;
                    height: 45px;
                    line-height: 44px;
                }
            }
        }

        .infoButtonMixin {
            padding: 6px 15px 6px 0px;
            margin-top: 5px;
            margin-bottom: 20px;
            cursor: pointer;
        }

        .chevron {
            margin-left: 3px;
        }

        .infoContainer {
            height: 57px;
            margin-top: 5px;
        }

        .row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            @media @mobile {
                flex-direction: column;
            }

            .col {
                .moreInfoButton {
                    .infoButtonMixin
                }

                @media @mobile {
                    width: 100%;
                }
                @media @tablet, @desktop {
                    width: calc(50% - 10px);
                }
            }
        }

        .center {
            width: 100%;

            :global {
                .ant-form-item-control {
                    display: flex;
                    justify-content: center;
                }
            }

            .moreInfoButton {
                .infoButtonMixin
            }
        }

        .submit {

            text-align: center;

            button {
                margin: 50px 0 0;
                width: 250px;
                height: 50px;
                font-size: 20px;
                border: unset;
                text-transform: uppercase;

                > span {
                    font-weight: 500;
                }
            }
        }
    }

    .treeStyle {
        color: #0C3D57;
        font-size: 1.2em;
        background-color: #ECEFF4;

         .treeNode {
            :global {
                .ant-tree-node-content-wrapper:hover {
                    background-color: rgb(216 113 183 / 20%) !important;
                    
                }
                
                .ant-tree-node-selected {
                    background-color: rgb(216 113 183 / 20%) !important;
                    .ant-tree-title {
                        font-weight: 400;
                    }
                }
                
                .ant-tree-title {
                    color: #0C3D57;
                 }
    
            }
         }
    }
}