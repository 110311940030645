.tcup {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 50px;

    .content {
        position: relative;
        max-width: 100%;
        max-height: 100%;

        .svgTCup {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;

            rect {
                max-height: 0;
            }

            .empty {
                fill: #fff;
            }
        }

        .shadow {
            position: absolute;
            left: -5%;
            z-index: 0;
            width: 110%;
        }

        @keyframes fill-tcup {
            from {
                y: 100%;
                height: 0;
            }

            to {
                height: 100%;
            }
        }

        .animated {
            position: absolute;
            animation-name: fill-tcup;
            animation-iteration-count: 1;
            animation-duration: 1s;
            transform-origin: bottom;
        }
    }

    .animation {
        position: absolute;
        z-index: 1;
        bottom: 0;
        right: 0;
        transform: translate(50%, 35%);
        margin: auto;
    }
}